import React from "react";
import { PopupWrapper } from "../../UI/PopupWrapper/PopupWrapper";
import ReactPlayer from "react-player";

function VideoPlayPopup({ onClose, openVideo }) {
  return (
    <PopupWrapper visible={openVideo?.open}>
      <div className="bg-[#fff] relative popUpShadow h-[400px] w-[800px] rounded-[10px] flex flex-col trialPop mt-[5%]   ">
        <div
          onClick={() => onClose(false)}
          className="justify-end cursor-pointer z-[10] absolute right-2 top-3 items-end flex mr-[1%]"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M18 6L6 18M6 6L18 18"
              stroke="#667085"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
          </svg>
        </div>

        <ReactPlayer
          style={{ objectFit: "contain", paddingBottom: 10 }}
          height={"100%"}
          width={"100%"}
          playing={true}
          controls={true}
          url={openVideo?.video}
        />
      </div>
    </PopupWrapper>
  );
}

export default VideoPlayPopup;
