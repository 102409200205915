import { Route, Router, Routes } from "react-router-dom";
import "./App.css";
import Home from "./Pages/Home";
import Templates from "./Pages/Templates";
import TandC from "./Pages/TandC";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import Refund from "./Pages/Refund";
import Shipping from "./Pages/Shipping";
import Pricing from "./Pages/Pricing";
import Poster from "./Pages/Poster";
import Transaction from "./Pages/Transaction";
import PurchaseLanding from "./Pages/Purchase/PurchaseLanding";
import PurchaseReview from "./Pages/Purchase/PurchaseReview";
import { ContextProvider } from "./Context";
import PurchaseSuccess from "./Pages/Purchase/PurchaseSuccess";
import { AuthContextProvider } from "./AuthContext";
import UserProfile from "./Pages/UserProfile";
import LandingWishes from "./Pages/DemoLandings/LandingWishes";
import LandingFilesShare from "./Pages/DemoLandings/LandingFilesShare";
import PaymentThirdParty from "./Pages/PaymentThirdParty";
import PitchForGifting from "./Pages/PitchForGifting";
import PitchForCorporates from "./Pages/PitchForCorporates";
import PitchForRestaurants from "./Pages/PitchForRestaurants";
import TemplatePurchase from "./Pages/InvitationPurchase/TemplatePurchase";
import ProtectedRoute from "./Components/ProtectedRoute";
import Contact from "./Pages/Contact";
import PurchaseResponse from "./Pages/InvitationPurchase/PurchaseResponse";
import PricingLanding from "./Pages/Pricing/PricingLanding";
import ClientsPage from "./Pages/ClientsPage/ClientsPage";
import DesktopTool from "./Pages/desktopTool/DesktopTool";
import Tutorials from "./Pages/Tutorials";
function App() {
  return (
    <div className="App">
      <AuthContextProvider>
        <ContextProvider>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/userprofile" element={<UserProfile />} />
            <Route path="/tandc" element={<TandC />} />
            <Route path="/privacypolicy" element={<PrivacyPolicy />} />
            <Route path="/refund" element={<Refund />} />
            <Route path="/shipping" element={<Shipping />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/tutorials" element={<Tutorials />} />
            {/* <Route path="/pricing" element={<Pricing />} /> */}
            {/* <Route path="/pricingNew" element={<PricingLanding />} /> */}
            <Route path="/clients" element={<ClientsPage />} />
            <Route path="/poster" element={<Poster />} />
            <Route path="/buynow" element={<PurchaseLanding />} />
            <Route path="/buynow/review" element={<PurchaseReview />} />
            <Route path="/payment/:albumId" element={<PurchaseSuccess />} />
            <Route path="/:slugId" element={<Templates />} />
            <Route
              path="/transaction/:transactionId"
              element={<Transaction />}
            />
            <Route path="/onlinewishbookdemo" element={<LandingWishes />} />
            <Route path="/sharedmemoriesdemo" element={<LandingFilesShare />} />
            <Route path="/thirdpartyPayment" element={<PaymentThirdParty />} />
            <Route path="/gifts" element={<PitchForGifting />} />
            <Route path="/corporates" element={<PitchForCorporates />} />
            <Route path="/restaurants" element={<PitchForRestaurants />} />
            {/* <Route path="/buyinvitation" element={<TemplatePurchase />} /> */}

            <Route
              path="/buyinvitation"
              element={<ProtectedRoute Component={TemplatePurchase} />}
            />
            <Route
              path="/buyinvitation/payment/:albumId"
              element={<PurchaseResponse />}
            />
            <Route path="/desktoptool" element={<DesktopTool />} />
          </Routes>
        </ContextProvider>
      </AuthContextProvider>
    </div>
  );
}

export default App;
