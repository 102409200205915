import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Context from "../Context";
import Navbar from "../Components/Navbar";
import Cover from "../Components/Home/Cover";
import CustomUrl from "../Components/Home/CustomUrl";
import Introduction from "../Components/Home/Introduction";
import PhotoShareTool from "../Components/Home/PhotoShareTool";
import PhotoShareToolDemo from "../Components/Home/PhotoShareToolDemo";
import WishTool from "../Components/Home/WishTool";
import WishToolDemo from "../Components/Home/WishToolDemo";
import Features from "../Components/Home/Features";
import Footer from "../Components/Home/Footer";
import HowItWorks from "../Components/Home/HowItWorks";
import PhotoShareToolDemoBanner from "../Components/Home/PhotoShareToolDemoBanner";
import WishToolDemoBanner from "../Components/Home/WishToolDemoBanner";
import AuthContext from "../AuthContext";
import Events from "../Components/Home/Events";
import axios from "axios";
import Clients from "../Components/Home/Clients";
import FaceDetection from "../Components/Home/FaceDetection";
import Pitch from "../Components/Home/Pitch";
import AlbumAndSelectionTool from "../Components/Home/SignatureAlbum";
import Invitation from "../Components/Home/Invitation";
import backToTop from "../Assets/Images/Home/backToTop.svg";
import SignupPage from "./Signup/SignupPage";
import SignatureAlbum from "../Components/Home/SignatureAlbum";
import SelectionTool from "../Components/Home/SelectionTool";
import whatsappContactIcon from "../Assets/Images/Home/whatsappContactIcon.svg";

function Home() {
  const { currentUser, googleSignOut } = useContext(AuthContext);
  const {
    setUserTitle,
    setUserCoverImage,
    setWishSelected,
    setFileShareSelected,
    setSelectedWishpackage,
    setSelectedPhotosharePackage,
    isM4myshot,
  } = useContext(Context);
  const [packagePricing, setPackagePricing] = useState({});
  const [wishBaseAmount, setWishBaseAmount] = useState("");
  const [sharedMemoriesBaseAmount, setSharedMemroiesBaseAmount] = useState("");

  useEffect(() => {
    setUserTitle("");
    setUserCoverImage();
    setWishSelected(false);
    setFileShareSelected(false);
    setSelectedWishpackage({});
    setSelectedPhotosharePackage({});
    if (currentUser?.isAnonymous) {
      googleSignOut();
    }
  }, []);

  // Save scroll position to local storage on scrolling
  const handleScroll = () => {
    const currentPosition = window.scrollY;
    sessionStorage.setItem("scrollPosition", currentPosition);
  };

  useEffect(() => {
    // Attach scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Remove scroll event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    // Scroll to the position set in local storage on page load
    const storedPosition = parseInt(
      sessionStorage.getItem("scrollPosition"),
      10
    );
    window.scrollTo({
      top: storedPosition,
      left: 0,
      behavior: "smooth",
    });
    getPackage();
  }, []);

  const getPackage = async () => {
    try {
      const response = await axios.get(
        `https://storage.googleapis.com/memshots_wishes/PHOTOTOOL_PACKAGES/photoToolPackages.json`
      );
      setPackagePricing(response?.data);
      setWishBaseAmount(response?.data?.wish[0]?.amount);
      setSharedMemroiesBaseAmount(response?.data?.photoShare[0]?.amount);
    } catch (err) {
      console.log(err);
    }
  };

  const shareToWhatsapp = () => {
    const encodedContent = isM4myshot
      ? "Hi, I would like to know about m4myshot."
      : "Hi, I would like to know about memshots.";
    const isMobileDevice = /Android|iPhone|iPad|iPod/i.test(
      navigator.userAgent
    );
    const formattedNumber = isM4myshot ? +12068223339 : 919986619796;
    if (isMobileDevice) {
      const url = `https://wa.me/${formattedNumber}?text=${encodedContent}`;
      window.open(url, "_blank");
    } else {
      const url = `https://web.whatsapp.com/send?phone=${formattedNumber}&text=${encodedContent}`;
      window.open(url, "_blank");
    }
  };

  return (
    <div className="containFull cursor-default">
      <Navbar packagePricing={packagePricing} />

      <Cover />
      <Introduction />
      <FaceDetection />
      <div className=" w-full flex justify-center px-[20px] lg:px-[80px]">
        <div className="horizontalSeperator max-w-[1440px]"></div>
      </div>
      <SelectionTool />
      <div className=" w-full flex justify-center px-[20px] lg:px-[80px]">
        <div className="horizontalSeperator max-w-[1440px]"></div>
      </div>
      <SignatureAlbum />
      {isM4myshot && (
        <>
          <div className=" w-full flex justify-center px-[20px] lg:px-[80px]">
            <div className="horizontalSeperator max-w-[1440px]"></div>
          </div>
          <Invitation />
        </>
      )}

      {!isM4myshot && (
        <>
          <div className=" w-full flex justify-center px-[20px] lg:px-[80px]">
            <div className="horizontalSeperator max-w-[1440px]"></div>
          </div>
          <WishTool
            setWishSelected={setWishSelected}
            setSelectedWishpackage={setSelectedWishpackage}
            packagePricing={packagePricing}
            wishBaseAmount={wishBaseAmount}
          />
          <div className=" w-full flex justify-center px-[20px] lg:px-[80px]">
            <div className="horizontalSeperator max-w-[1440px]"></div>
          </div>
          {/* <WishToolDemoBanner /> */}
          {/* <WishToolDemo /> */}
          <PhotoShareTool
            setFileShareSelected={setFileShareSelected}
            setSelectedPhotosharePackage={setSelectedPhotosharePackage}
            packagePricing={packagePricing}
            sharedMemoriesBaseAmount={sharedMemoriesBaseAmount}
          />
          <div className=" w-full flex justify-center px-[20px] lg:px-[80px]">
            <div className="horizontalSeperator max-w-[1440px]"></div>
          </div>
          <Invitation />

          <Pitch />
        </>
      )}
      {/* <PhotoShareToolDemoBanner /> */}

      {/*  <PhotoShareToolDemo />*/}
      <Clients limit={5} />
      <div className=" w-full flex justify-center px-[20px] lg:px-[80px]">
        <div className="horizontalSeperator max-w-[1440px]"></div>
      </div>
      <Events limit={4} />
      {/* <CustomUrl /> */}
      <Features />
      <Footer />

      <div
        // onClick={() => {
        //   window.scrollTo({
        //     top: 0,
        //     left: 0,
        //     behavior: "smooth",
        //   });
        // }}
        onClick={shareToWhatsapp}
        className="fixed bottom-[35px] right-[10px] cursor-pointer flex gap-[10px] items-center"
      >
        <div className="px-[16px] py-[10px] bg-white text-black rounded-[10px]">
          Chat with us 👋
        </div>
        <img
          src={whatsappContactIcon}
          alt=""
          className="w-[32px] lg:w-[52px] h-[32px] lg:h-[52px] backToTopShadow rounded-full"
        />
      </div>
    </div>
  );
}

export default Home;
